
ul.entities {
	li {
		.icon {
			border: none;
			background: none;
			text-align: center;
			font-size: 20px;
			line-height: 48px;
		}
	}
}
